import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { GatsbyImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import { isValidImage } from "../../utilities/isValidImage"
import t, { KEYS } from "../../translations"

import ContentTable from "./ContentTable"
import NewsletterBlock from "./NewsletterBlock"
import ReadingTime from "../../components/ReadingTime"
import ShareIcons from "./ShareIcons"

import styles from "./BlogPost.module.scss"

import { trackEvent } from "../../analytics/trackEvent"

const BlogPostAuthor = props => {
  const { profilePicture, name, datePublished, dateUpdated, locale } = props
  return (
    <div
      className={[styles.author, "d-flex", "align-items-center"].join(" ")}
      itemProp="author"
      itemScope
      itemType="https://schema.org/Person"
    >
      {profilePicture && (
        <GatsbyImage
          image={profilePicture.gatsbyImageData}
          alt={name}
          className={[styles.avatar, "rounded-circle"].join(" ")}
          itemProp="image"
        />
      )}
      {profilePicture && (
        <meta itemProp="image" content={profilePicture.file.url} />
      )}
      <div className={styles.info}>
        <div className={styles.name} itemProp="name">
          {name}
        </div>
        <div className={styles.datePublished}>
          <span className="date-published">{datePublished}</span> -{" "}
          {t.translate(KEYS.UPDATED_ON, locale)}{" "}
          <span className="date-modified">{dateUpdated}</span>
        </div>
      </div>
    </div>
  )
}

BlogPostAuthor.propTypes = {
  profilePicture: PropTypes.object,
  name: PropTypes.string.isRequired,
  datePublished: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
}

const BlogPost = props => {
  const { blogPost, pageUrl, locale, blogCategoryStyle } = props
  const contentRef = useRef(null)

  const htmlContent = blogPost.body.childMarkdownRemark.html
  const timeToRead = _.get(
    blogPost,
    "body.childMarkdownRemark.timeToRead"
  ).toString()
  const [toc, setToc] = useState([])

  function generateLinkMarkup($contentElement) {
    const headings = [...$contentElement.querySelectorAll("h1, h2, h3")]
    const parsedHeadings = headings.map(heading => {
      return {
        title: heading.innerText,
        depth: heading.nodeName.replace(/\D/g, ""),
        id: heading.getAttribute("id")
      }
    })

    return parsedHeadings
  }

  const findClosestLink = element => {
    if (element.tagName === "A") {
      return element
    }

    while (element.parentNode) {
      element = element.parentNode
      if (element.tagName === "A") {
        return element
      }
    }

    return null
  }

  const handleLinkClick = event => {
    const element = findClosestLink(event.target)
    if (element) {
      trackEvent(
        "click_request_marketing resource",
        element.href,
        {
          user_type: "{{country_code}}-prospect"
        },
        {
          category: "interaction",
          label: "{{event_label}}"
        }
      )
    }
  }

  useEffect(() => {
    if (contentRef.current) {
      const contentHeadings = generateLinkMarkup(contentRef.current)
      setToc(contentHeadings)

      // const wordCount = getWordCount(contentRef.current)
      // const wpm = 225 // average reading speed
      // setReadingTime(parseInt(wordCount / wpm))
    }

    contentRef.current.addEventListener("click", handleLinkClick)

    return () => {
      contentRef.current.removeEventListener("click", handleLinkClick)
    }
  }, [contentRef])
  return (
    <Container className={styles.slimContainer}>
      <div className={styles.head}>
        <h1 className={[styles.title, "text-primary"].join(" ")}>
          {blogPost.title}
        </h1>
        {blogPost.author && (
          <BlogPostAuthor
            {...blogPost.author}
            locale={locale}
            datePublished={blogPost.datePublished}
            dateUpdated={blogPost.dateUpdated}
          />
        )}
        {blogPost && (
          <div className="mt-2">
            <ReadingTime locale={locale} timeString={timeToRead} />
          </div>
        )}
        <div style={{ position: "relative" }}>
          {toc && toc.length > 0 && blogCategoryStyle !== "glossary" && (
            <ContentTable headings={toc} locale={locale} />
          )}
          <div className={styles.mainImage}>
            {isValidImage(blogPost.mainImage) && (
              <GatsbyImage
                image={blogPost.mainImage.gatsbyImageData}
                alt={blogPost.mainImage.description || ""}
              />
            )}
            {isValidImage(blogPost.mainImage) && (
              <meta itemProp="image" content={blogPost.mainImage.file.url} />
            )}
          </div>
          <div
            className={styles.body}
            dangerouslySetInnerHTML={{
              __html: htmlContent
            }}
            ref={contentRef}
          />
          <hr className="my-4" />
          <Row>
            <Col md>
              <NewsletterBlock locale={locale} />
            </Col>
            <Col
              md
              className="d-flex justify-content-center flex-md-grow-0 mt-md-5 mb-5"
              style={{ paddingTop: "1rem" }}
            >
              <ShareIcons url={pageUrl} title={blogPost.title} />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  )
}

BlogPost.propTypes = {
  blogPost: PropTypes.shape({
    body: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string
      })
    }).isRequired,
    updatedAt: PropTypes.string,
    dateUpdated: PropTypes.string,
    datePublished: PropTypes.string,
    readTime: PropTypes.string,
    mainImage: PropTypes.object,
    datePublishedPropContent: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.object
  }).isRequired,
  blogCategoryStyle: PropTypes.string,
  pageUrl: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired
}

export default BlogPost
