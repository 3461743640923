import React from "react"
import PropTypes from "prop-types"
import t from "../../translations"
import PageHeadForm from "../../components/PageHead1/PageHeadForm"
import { getLocaleLanguageCode } from "../../utilities/utilities"

const localeForm = locale => {
  const languageCode = getLocaleLanguageCode(locale)

  switch (languageCode) {
    case "fr":
      return {
        portalId: "2856475",
        formId: "79d2a54e-175d-405a-b175-73b975a0e255"
      }
    case "en":
      return {
        portalId: "2856475",
        formId: "a244d30e-7c39-47c3-90bf-a776eb7c629d"
      }
    case "es":
      return {
        portalId: "2856475",
        formId: "00372455-44e7-4077-93fd-0bfbaaf58cd2"
      }
    case "de":
      return {
        portalId: "2856475",
        formId: "34bd117b-3896-41f6-b4f9-395f78f0cca9"
      }
    case "it":
      return {
        portalId: "2856475",
        formId: "1deda191-c92b-4dda-854d-e38fd21faad1"
      }
    case "nl":
      return {
        portalId: "2856475",
        formId: "21a50380-99ba-4328-8274-73152c20ce81"
      }
    case "da":
      return {
        portalId: "2856475",
        formId: "6537bb30-0df2-4331-aa73-088fe330c41d"
      }
  }
}

const NewsletterBlock = props => {
  const { locale } = props

  const { formId, portalId } = localeForm(locale)

  return (
    <div>
      <h2 className="h5">
        {t.translate(t.KEYS.SUBSCRIBE_TO_NEWSLETTER, locale)}
      </h2>
      <PageHeadForm
        portalId={portalId}
        formId={formId}
        formStyle="inline"
        locale={props.locale}
      />
    </div>
  )
}

NewsletterBlock.propTypes = {
  locale: PropTypes.string.isRequired
}

export default NewsletterBlock
